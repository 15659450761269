import { useState, useRef, useCallback, FormEvent } from "react";

import { API_URLS } from "@api/consts";

import { DownloadFormFieldNames } from "./types";

export const useDownloadForm = (
  sendgridId?: string,
  customFieldId?: string
) => {
  const [status, setStatus] = useState<"success" | "error">();
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);

  const onClose = useCallback(() => {
    setStatus(undefined);
  }, []);

  const handleResetForm = useCallback(() => {
    if (!formRef.current) return;

    formRef.current?.reset();
  }, []);

  const handleOnSubmit = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    try {
      const formData = new FormData(event.currentTarget);
      const firstName = formData.get(
        DownloadFormFieldNames.firstName
      );
      const lastName = formData.get(DownloadFormFieldNames.lastName);
      const companyName = formData.get(
        DownloadFormFieldNames.companyName
      );
      const email = formData.get(DownloadFormFieldNames.email);
      const agreement = formData.get(
        DownloadFormFieldNames.agreement
      );

      if (email && agreement) {
        setIsButtonEnabled(false);

        const response = await fetch(API_URLS.download, {
          body: JSON.stringify({
            sendgridId,
            customFieldId,
            firstName,
            lastName,
            companyName,
            email
          }),
          headers: {
            "Content-Type": "application/json"
          },
          method: "POST"
        });
        const result = await response.json();

        if (!result?.success) {
          throw new Error("Unexpected error");
        }

        setStatus("success");
        handleResetForm();
      }
    } catch (e) {
      setStatus("error");
    } finally {
      setIsButtonEnabled(true);
    }
  };

  return {
    status,
    isButtonEnabled,
    formRef,
    handleOnSubmit,
    onClose
  };
};
