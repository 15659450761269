import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import styled, { css } from "styled-components";
import { media } from "@pagepro-monorepo/ui";

import CoverBgIcon from "@assets/svg/cover-bg.svg";
import CoverBgSecondaryIcon from "@assets/svg/cover-bg-secondary.svg";
import Image from "@components/atoms/Image";

export const Wrapper = styled(FlexBox).attrs({
  width: "100%",
  height: ["21.25rem", "18.75rem"],
  justifyContent: "center",
  overflow: "hidden",
  pt: ["0px", "2rem"]
})`
  position: absolute;
  z-index: 0;
  bottom: 0px;
  left: 0px;

  @media ${media.maxMobile} {
    top: 0px;
  }
`;

export const CoverBackground = styled(CoverBgIcon)`
  position: absolute;
  top: calc(100% - 15.625rem);
  z-index: -1;

  @media ${media.maxMobile} {
    top: -18.75rem;
  }
`;

export const CoverBackgroundSecondary = styled(CoverBgSecondaryIcon)`
  position: absolute;
  top: calc(100% - 15.625rem);
  z-index: -1;

  @media ${media.maxMobile} {
    top: -18.75rem;
  }
`;

export const CoversWrapper = styled(FlexBox).attrs({
  alignItems: ["flex-end", "center"]
})`
  position: relative;
  height: fit-content;
  z-index: 1;
  top: 3rem;
`;

export const PrimaryImage = styled(Image).attrs({
  width: 200,
  height: 274
})<{ $hasSecondaryImage: boolean }>`
  object-fit: cover;
  object-position: top;

  ${({ $hasSecondaryImage }) =>
    $hasSecondaryImage &&
    css`
      position: relative;
      left: 1rem;
    `}
`;

export const SecondaryImage = styled(Image).attrs({
  width: 160,
  height: 207
})`
  position: relative;
  object-fit: contain;
  right: 1rem;
`;

export const FileType = styled.div<{ $hasSecondaryImage: boolean }>`
  position: absolute;
  top: -2rem;
  right: -2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  z-index: 1;
  font-weight: 700;
  filter: drop-shadow(0 0.1875rem 1.5rem rgba(24, 17, 17, 0.22));

  ${({ $hasSecondaryImage }) =>
    $hasSecondaryImage &&
    css`
      top: 0px;
      right: -1rem;
      @media ${media.maxMobile} {
        top: -2rem;
        right: calc(50% - 4rem);
      }
    `}
`;
