/* eslint-disable import/order */
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import styled from "styled-components";
import Button from "@pagepro-monorepo/ui/lib/components/atoms/Button";

import downloadBg from "public/assets/images/download-bg-image.png";
import Input from "@components/atoms/fields/Input";

export const Background = styled.div`
  background-image: url(${downloadBg.src});
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled(FlexBox)`
  position: relative;
  z-index: 1;
`;

export const InputField = styled(Input)`
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  min-width: max-content;
`;

export const Form = styled.form`
  width: 100%;
`;
