import React from "react";

import * as Styled from "./styles";
import { CoverProps } from "./types";

const Cover = ({
  primaryImage,
  secondaryImage,
  fileType,
  useAlternativeBackground
}: CoverProps) => {
  if (!primaryImage) return null;

  return (
    <Styled.Wrapper>
      {!useAlternativeBackground ? (
        <Styled.CoverBackground />
      ) : (
        <Styled.CoverBackgroundSecondary />
      )}

      <Styled.CoversWrapper>
        <Styled.PrimaryImage
          src={primaryImage}
          alt="Primary Cover"
          $hasSecondaryImage={!!secondaryImage}
        />
        {secondaryImage && (
          <Styled.SecondaryImage
            src={secondaryImage}
            alt="Primary Cover"
          />
        )}

        {fileType && (
          <Styled.FileType $hasSecondaryImage={!!secondaryImage}>
            {fileType}
          </Styled.FileType>
        )}
      </Styled.CoversWrapper>
    </Styled.Wrapper>
  );
};

export default Cover;
